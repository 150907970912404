import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'sucstu-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <footer class="footer" *ngIf="!isSales">

      <div class="footer-links-container">
        <div class="footer-links__group">
<!--          <h5 class="footer-links__title text-medium">more Info</h5>-->
<!--          <ul class="footer-links__list">-->
<!--            <li class="footer-link__item"><a routerLink="/reviews">Reviews</a></li>-->
<!--            <li class="footer-link__item"><a routerLink="/inspiration">Inspiration</a></li>-->
<!--            <li [attr.test-id]="'footer__gift-button'" class="footer-link__item"><a routerLink="/gift">Send a Gift</a></li>-->
<!--            <li class="footer-link__item"><a routerLink="/carecards">Succulent Care</a></li>-->
<!--            <li class="footer-link__item"><a routerLink="/blog">Blog</a></li>-->
<!--            <li class="footer-link__item"><a href="https://gifting.succulent.studio" target="a_blank">Corporate Gifting</a></li>-->
<!--          </ul>-->
        </div>
        <div class="footer-links__group">
<!--          <h5 class="footer-links__title text-medium">questions?</h5>-->
<!--          <ul class="footer-links__list">-->
<!--            <li class="footer-link__item"><a href="mailto:hello@succulent.studio">hello@succulent.studio</a></li>-->
<!--            <li class="footer-link__item"><a routerLink="/faq">FAQ</a></li>-->
<!--            <li class="footer-link__item"><a routerLink="/privacy-policy">Privacy Policy</a></li>-->
<!--            <li class="footer-link__item"><a routerLink="/terms">Terms of Service</a></li>-->
<!--          </ul>-->
        </div>
        <div class="footer-links__group">
<!--          <h5 class="footer-links__title text-medium">company</h5>-->
<!--          <ul class="footer-links__list">-->
<!--            <li class="footer-link__item"><a routerLink="/about">About</a></li>-->
<!--          </ul>-->
        </div>
      </div>

      <div class="footer-social-links">
<!--        <div class="footer-social-links__group">-->
<!--          <a-->
<!--            class="social-link__item"-->
<!--            href="https://www.facebook.com/sucstu"-->
<!--            target="a_blank">-->
<!--            <img src="/assets/images/facebook@2x.png" alt="Facebook Icon">-->
<!--          </a>-->
<!--          <a-->
<!--            class="social-link__item"-->
<!--            href="https://www.twitter.com/sucstu"-->
<!--            target="a_blank">-->
<!--            <img src="/assets/images/twitter.svg" alt="Twitter Icon">-->
<!--          </a>-->
<!--          <a-->
<!--            class="social-link__item"-->
<!--            href="https://www.pinterest.com/SUCSTU/"-->
<!--            target="a_blank">-->
<!--            <img src="/assets/images/pinterest-black@2x.png" alt="Pinterest Icon">-->
<!--          </a>-->
<!--          <a-->
<!--            class="social-link__item"-->
<!--            href="https://www.instagram.com/sucstu"-->
<!--            target="a_blank">-->
<!--            <img src="/assets/images/instagram@2x.png" alt="Instagram Icon">-->
<!--          </a>-->
<!--        </div>-->
        <a
          (click)="moveToTop()"
          class="go-top-btn"><img src="/assets/images/go-top.png" alt=""></a>
      </div>
    </footer>
  `
})
export class FooterComponent {
  constructor(
    private locationService: Location,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }
  moveToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
  get isSales() {
    return this.locationService.path() === '/sales' || this.locationService.path() === '/churn' ;
  }

}
